@import '../../styles/variables.scss';

.search {
  padding: 50px;
  @media (max-width: $tablet) {
    padding: 50px 0px;
  }
  &__form {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    background-color: purple;
    @media (max-width: $mobile) {
      padding: 15px 10px;
    }
  }
  &__input {
    flex: 1 1 auto;

    margin-right: 20px;
    input {
      width: 100%;
      background-color: white;
      font-size: 18px;
      border-radius: 10px;
      padding: 10px 15px;
    }
  }
  &__button {
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
    color: white;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
