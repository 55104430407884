@import '../../styles/variables.scss';
.pagination {
  display: flex;
  margin-top: 20px;
  gap: 5px;
  flex-wrap: wrap;
  &__item {
    cursor: pointer;
    border: 1px solid gray;
    flex: 0 1 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}

.active {
  border: 1px solid blue;
  color: blue;
}
