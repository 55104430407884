@import '../../styles/variables.scss';

.akin {
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  &__item {
    cursor: pointer;
    background-color: #f5f9ff;
    padding: 4px 7px;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: #e4ebf5;
    }
  }
}
