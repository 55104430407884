@import '../../styles/variables.scss';

.table {
  margin-top: 50px;
  width: 100%;
  //   min-width: 850px;

  @media (max-width: $tablet) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  &__top {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    @media (max-width: $tablet) {
      min-width: 500px;
      gap: 10px;
    }
  }
  &__title {
    flex: 0 1 25%;
    //  min-width: 250px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  &__items {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    @media (max-width: $tablet) {
      min-width: 500px;
    }
  }
  &__item {
    //  min-width: 250px;
    flex: 0 1 25%;
  }
}
