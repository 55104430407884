@import '../../styles/variables.scss';

.filter {
  &__top {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid gray;
    margin-bottom: 4px;
    &_active {
      border-bottom: 8px solid green;
    }
  }
  &__bottom {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid gray;
    &_active {
      border-top: 8px solid red;
    }
  }
}
