@import-normalize;
@import 'null.scss';
@import 'variables.scss';

html {
  height: 100%;
}
body {
  font-weight: 400;
  font-size: 16px;
  height: 100%;
}
#root {
  height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  max-width: 1410px;
  margin: 0 auto;
  padding: 0px 15px;
}
.main {
  flex: 1 1 auto;
}
