@import '../../styles/variables.scss';
.header {
  padding: 10px 0px;
  &__button {
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
    color: white;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
